import { memo } from "react";
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

import type { IconProps } from "@tamagui/helpers-icon";
import { themed } from "@tamagui/helpers-icon";

// @ts-expect-error To preserve the same API as lucide icons because of the
// theme(component) function
const Icon = props => (
  <Svg
    viewBox="0 0 555 111"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <G fill="#002160" clipPath="url(#a)">
      <Path d="M541.087 0H555v109.204h-13.913V96.676C535.363 105.299 524.72 111 512.938 111c-22.75 0-39.933-17.899-39.933-41.342 0-23.443 17.183-41.334 39.933-41.334 11.782 0 22.258 5.048 28.149 13.67V0Zm.824 69.658c0-16.111-11.616-28.483-27.326-28.483s-27.334 12.372-27.334 28.483 11.616 28.483 27.334 28.483 27.326-12.372 27.326-28.483Zm-111.509 41.334c-23.565 0-40.582-17.254-40.582-41.334 0-24.08 17.017-41.334 39.6-41.334 22.583 0 38.618 18.065 38.618 40.689 0 1.787-.167 2.929-.167 3.74v1.3h-63.988c.815 13.835 12.931 24.41 26.677 24.41 11.949 0 17.998-5.693 23.074-13.504l11.458 5.858C458.543 102.7 446.603 111 430.393 111l.009-.008ZM404.224 62.5h49.585c-1.307-12.37-11.783-21.97-24.714-21.97-12.93 0-23.565 11.23-24.871 21.97Zm-128.068 5.37v41.334h-14.071V30.112h14.071v11.395c5.076-7.812 13.421-13.183 25.37-13.183 20.453 0 32.078 13.505 32.078 34.499v46.381h-14.071v-44.75c0-14.325-7.198-23.113-20.944-23.113-13.746 0-22.425 10.253-22.425 26.53h-.008Zm-44.343-37.758h13.913v79.092h-13.913V96.51c-5.725 8.954-16.367 14.482-28.15 14.482-22.749 0-39.932-17.9-39.932-41.334 0-23.435 17.183-41.334 39.932-41.334 11.783 0 22.259 4.883 28.15 13.836V30.112Zm.816 39.546c0-16.111-11.616-28.483-27.326-28.483s-27.327 12.372-27.327 28.483 11.617 28.483 27.327 28.483 27.326-12.372 27.326-28.483Zm-67.558-40.523v13.993c-.167 0-.491 0-.982-.165-.658 0-1.798-.166-2.946-.166-14.894 0-23.731 11.718-23.731 28.806v37.593h-13.913V30.112h13.746v13.993c3.437-9.11 14.071-15.3 24.389-15.3 1.148 0 1.797.165 2.455.165.491.165.815.165.982.165ZM363.585 6.347h-14.07v23.749h-12.274v12.528h12.274V86.4c0 15.466 9.003 24.577 24.056 24.577 5.4 0 9.494-.977 12.273-2.441V95.518c-2.455 1.787-5.891 2.441-9.003 2.441-9.162 0-13.256-4.228-13.256-13.505V42.633h22.259V30.104h-22.259V6.347Z" />
      <Path
        fillRule="evenodd"
        d="M55.8 13.67c-23.232 0-42.062 18.727-42.062 41.83 0 23.104 18.83 41.83 42.063 41.83 20.894 0 38.226-15.151 41.504-35.003H45.676V48.665h65.509s.424 4.51.424 6.827c0 30.65-24.988 55.492-55.8 55.492C24.996 110.984 0 86.151 0 55.5 0 24.85 24.988.008 55.8.008a55.83 55.83 0 0 1 49.801 30.428H89.485A42.08 42.08 0 0 0 55.8 13.67Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h555v111H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

Icon.displayName = "GrantedTextLogo";

export const GrantedTextLogo = memo<IconProps>(themed(Icon));
