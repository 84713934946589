import { useRouter as useNextRouter } from "next/router";
import type React from "react";
import { useEffect, useState } from "react";
import { useRouter } from "solito/router";

import { Menu as MenuIcon, UserCircle } from "@tamagui/lucide-icons";

import {
  Adapt,
  Button,
  FullscreenSpinner,
  GrantedTextLogo,
  Paragraph,
  Popover,
  Separator,
  type StackProps,
  XStack,
  YStack,
} from "@medbillai/ui";

import { loginUrl } from "@medbillai/utils/urls";

import { useAuth } from "../../lib/auth";
import { NavTabs } from "./components/NavTabs.web";

export type HomeLayoutProps = {
  children?: React.ReactNode;
  padded?: boolean;
  fullPage?: boolean;
};

export const HomeLayout = ({
  children,
  fullPage = false,
  padded = false,
}: HomeLayoutProps) => {
  const { isReady, isAuthenticated } = useAuth();
  const router = useRouter();

  if (!isReady) {
    return <FullscreenSpinner />;
  }
  if (isReady && !isAuthenticated) {
    router.push(loginUrl());
    return <FullscreenSpinner />;
  }

  return (
    <YStack f={1}>
      <YStack
        gap="$4"
        borderWidth="$0"
        borderBottomColor="$strokeSecondary"
        borderStyle="solid"
        borderBottomWidth="$0.5"
        jc="center"
        px="$4"
        backgroundColor="$color1"
      >
        <XStack jc="space-between" $sm={{ py: "$2.5" }} ai="center">
          <YStack justifyContent="center" alignItems="center">
            <GrantedTextLogo width={111} height={22} />
          </YStack>
          <YStack $sm={{ display: "none" }}>
            <NavTabs orientation="horizontal" size="$4" />
          </YStack>
          <YStack $gtSm={{ display: "none" }}>
            <MobileNavbar>
              <YStack gap="$5" width="100%" alignItems="flex-end">
                <NavTabs orientation="vertical" f={1} width="100%" size="$3" />
                <Separator width="100%" />
                {isAuthenticated && <WithUserDetail ai="center" gap="$4" />}
              </YStack>
            </MobileNavbar>
          </YStack>
        </XStack>
      </YStack>

      <YStack
        {...(fullPage && { flex: 1 })}
        {...(padded && {
          maxWidth: 800,
          mx: "auto",
          px: "$2",
          width: "100%",
        })}
      >
        {children}
      </YStack>
    </YStack>
  );
};

export const MobileNavbar = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const router = useNextRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, setOpen]);
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      size="$5"
      stayInFrame={{ padding: 20 }}
    >
      <Popover.Trigger asChild>
        <Button
          backgroundColor="$navy"
          chromeless
          p="$1.5"
          size="$2.5"
          animation="medium"
          opacity={open ? 0.8 : 1}
          onPress={() => setOpen(!open)}
          icon={<MenuIcon size={26} color="white" />}
        />
      </Popover.Trigger>

      <Adapt platform="web" when="sm">
        <Popover.Sheet
          zIndex={100000000}
          modal
          dismissOnSnapToBottom
          snapPointsMode="fit"
          animation="medium"
        >
          <Popover.Sheet.Frame>
            <Popover.Sheet.ScrollView>
              <Adapt.Contents />
            </Popover.Sheet.ScrollView>
          </Popover.Sheet.Frame>
          <Popover.Sheet.Overlay zIndex={100} />
        </Popover.Sheet>
      </Adapt>

      <Popover.Content>
        <Popover.ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <YStack miw={230} p="$3" ai="flex-end">
            {children}
          </YStack>
        </Popover.ScrollView>
      </Popover.Content>
    </Popover>
  );
};

const WithUserDetail = ({ ...props }: StackProps) => {
  const { user } = useAuth();
  return (
    <XStack gap="$2" {...props} pb="$2">
      <YStack ai="center">
        <Paragraph theme="alt1">{user?.profile?.email}</Paragraph>
      </YStack>
      <UserCircle />
    </XStack>
  );
};
